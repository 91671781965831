import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import _ from "lodash";
import DropElement from "./DropElement";
import { ITestStep } from "../../models";
import { RadioGroup, Radio } from "../Radio";
import ErrorMessage from "./ErrorMessage";
import ErrorStrategySelect from "./ErrorStrategySelect";
import { NumberTextField } from "../Inputs";

const useStyles = makeStyles((theme: Theme) => ({
  inputBlock: {
    marginBottom: theme.spacing(4),
  },
}));

interface IElementIsActionAction {
  testStep: ITestStep;
}

const ElementIsAction: React.FC<IElementIsActionAction> = observer(
  ({ testStep }) => {
    const classes = useStyles();
    const { t } = useTranslation(["actions", "common"]);

    const bounced = useCallback(
      () => _.debounce(testStep.save, 1000),
      [testStep.save],
    );

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
      testStep.setValue(event.target.value);
      testStep.save();
    };

    const onChangeScrollValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      testStep.setScroll(parseInt(e.target.value));
      bounced();
    };

    return (
      <>
        <Grid item className={classes.inputBlock}>
          <RadioGroup
            row
            labelText={t("element_parameters")}
            value={testStep.value || "true"}
            onChange={onChangeValue}
          >
            <Radio value="true" label={t(testStep.type)} />
            <Radio value="false" label={t(`not_${testStep.type}`)} />
          </RadioGroup>
        </Grid>
        <Grid item className={classes.inputBlock}>
          <DropElement label={t("target_element")} testStep={testStep} />
        </Grid>
        <NumberTextField
          labelText={t("scroll_to")}
          maxLength={8}
          value={testStep.scroll?.toString()}
          onChange={onChangeScrollValue}
          onBlur={testStep.save}
        />
        <ErrorMessage testStep={testStep} />
        <ErrorStrategySelect testStep={testStep} />
      </>
    );
  },
);

export default ElementIsAction;
