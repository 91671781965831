import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import _ from "lodash";
import { TextField } from "../Inputs";
import DropElement from "./DropElement";
import { ITestStep } from "../../models";
import ErrorStrategySelect from "./ErrorStrategySelect";
import ErrorMessage from "./ErrorMessage";
import { NumberTextField } from "../Inputs";

const useStyles = makeStyles((theme: Theme) => ({
  inputBlock: {
    marginBottom: theme.spacing(4),
  },
}));

interface IElementHasAction {
  testStep: ITestStep;
}

const ElementHasAction: React.FC<IElementHasAction> = observer(
  ({ testStep }) => {
    const classes = useStyles();
    const { t } = useTranslation(["actions", "common"]);
    const bounced = useCallback(
      () => _.debounce(testStep.save, 1000),
      [testStep.save],
    );

    const onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length > 255) return;
      testStep.setValue(e.target.value);
      bounced();
    };

    const onChangeAttributeName = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length > 50) return;
      testStep.setAttributeName(e.target.value);
      bounced();
    };

    const onChangeScrollValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      testStep.setScroll(parseInt(e.target.value));
      bounced();
    };

    return (
      <>
        <Grid item className={classes.inputBlock}>
          <TextField
            labelText={t(`${testStep.type}_name`)}
            helperTextRight={t("common:characters_left", {
              number: 50 - (testStep.attributeName?.length || 0),
            })}
            inputBaseProps={{
              fullWidth: true,
              onChange: onChangeAttributeName,
              value: testStep.attributeName || "",
              onBlur: testStep.save,
            }}
          />
        </Grid>
        <Grid item className={classes.inputBlock}>
          <TextField
            labelText={t(`${testStep.type}_value`)}
            helperTextRight={t("common:characters_left", {
              number: 255 - (testStep.value?.length || 0),
            })}
            inputBaseProps={{
              rows: 5,
              maxRows: 5,
              fullWidth: true,
              onChange: onChangeValue,
              value: testStep.value || "",
              multiline: true,
              onBlur: testStep.save,
            }}
          />
        </Grid>
        <Grid item className={classes.inputBlock}>
          <DropElement label={t("target_element")} testStep={testStep} />
        </Grid>
        <NumberTextField
          labelText={t("scroll_to")}
          maxLength={8}
          value={testStep.scroll?.toString()}
          onChange={onChangeScrollValue}
          onBlur={testStep.save}
        />
        <ErrorMessage testStep={testStep} />
        <ErrorStrategySelect testStep={testStep} />
      </>
    );
  },
);

export default ElementHasAction;
