import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import _ from "lodash";
import { NumberTextField, Checkbox } from "../Inputs";
import DropElement from "./DropElement";
import { ITestStep } from "../../models";
import { RadioGroup, Radio } from "../Radio";
import ErrorStrategySelect from "./ErrorStrategySelect";

const useStyles = makeStyles((theme: Theme) => ({
  inputBlock: {
    marginBottom: theme.spacing(4),
  },
  checkbox: {
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(-1.375),
  },
}));

interface IWaitForElementAction {
  testStep: ITestStep;
}

const WaitForElementAction: React.FC<IWaitForElementAction> = observer(
  ({ testStep }) => {
    const classes = useStyles();
    const { t } = useTranslation(["actions"]);
    const [checked, setChecked] = useState(!!testStep.value);
    const bounced = useCallback(
      () => _.debounce(testStep.save, 1000),
      [testStep.save],
    );

    const onReverse = (event: React.ChangeEvent<HTMLInputElement>) => {
      testStep.setReverse(event.target.value === "true");
      testStep.save();
    };

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      testStep.setValue(e.target.value);
      bounced();
    };

    const onChecked = () => {
      setChecked(!checked);
      if (!checked) testStep.setValue("");
      testStep.save();
    };

    const onChangeScrollValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      testStep.setScroll(parseInt(e.target.value));
      bounced();
    };

    const timeout = (!checked && "500") || testStep.value || "";

    return (
      <>
        <Grid item className={classes.inputBlock}>
          <RadioGroup
            row
            labelText={t("wait_for_element")}
            value={testStep.options.reverse?.toString()}
            onChange={onReverse}
          >
            <Radio value="false" label={t(testStep.type)} />
            <Radio value="true" label={t(`not_${testStep.type}`)} />
          </RadioGroup>
        </Grid>
        <Grid item className={classes.inputBlock}>
          <DropElement label={t("target_element")} testStep={testStep} />
        </Grid>
        <NumberTextField
          labelText={t("scroll_to")}
          maxLength={8}
          value={testStep.scroll?.toString()}
          onChange={onChangeScrollValue}
          onBlur={testStep.save}
        />
        <Grid item className={classes.inputBlock}>
          <NumberTextField
            labelText={t("timeout_milliseconds")}
            maxLength={8}
            value={timeout}
            onChange={onChangeValue}
            onBlur={testStep.save}
            disabled={!checked}
          />
          <Checkbox
            className={classes.checkbox}
            label={t("override_timeout")}
            checked={checked}
            onChange={onChecked}
          />
        </Grid>
        <ErrorStrategySelect testStep={testStep} />
      </>
    );
  },
);

export default WaitForElementAction;
